/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { object } from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Typography } from '@one-thd/sui-atomic-components';

const DraftBanner = ({ details }) => {
  if (_isEmpty(details)) {
    return null;
  }

  return (
    <div className="sui-absolute sui-z-max sui-top-0 sui-left-0 sui-right-0 sui-bg-warning sui-p-8 sui-h-32">
      <Typography variant="h4" weight="display" height="loose">{details?.productName} - Draft </Typography>
    </div>
  );
};

DraftBanner.propTypes = {
  details: object
};

DraftBanner.defaultProps = {
  details: {
    productName: 'Draft'
  }
};

export default DraftBanner;
